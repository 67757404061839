<template>
    <div>
        <b-row>
            <b-col cols="12" md="3">
                <p class="font-weight-medium  mb-3">{{ $t('form').toUpper() }}</p>
                <ValidationObserver ref="formModalValidate">
                    <div class="border rounded p-4">
                        <ValidationProvider name="role_id"
                                            rules="required"
                                            v-slot="{ valid, errors }">
                            <b-form-group :label="$t('role')">
                                <multi-selectbox
                                    v-model="formData.role_id" :multiple="false"
                                    :options="giveableRoleOptions" :validate-error="errors[0]"
                                ></multi-selectbox>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="faculty_code"
                                            rules=""
                                            v-slot="{ valid, errors }">
                            <b-form-group :label="$t('faculty')">
                                <faculty-selectbox v-model="formData.faculty_code"
                                                   :state="errors[0] ? false : null"/>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="department_code"
                                            rules=""
                                            v-slot="{ valid, errors }">
                            <b-form-group :label="$t('department')">
                                <department-selectbox v-model="formData.department_code"
                                                      :faculty_code="formData.faculty_code"
                                                      :state="errors[0] ? false : null"/>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="program_code"
                                            rules=""
                                            v-slot="{ valid, errors }">
                            <b-form-group :label="$t('program')">
                                <program-selectbox v-model="formData.program_code"
                                                   :state="errors[0] ? false : null"
                                                   :faculty_code="formData.faculty_code"
                                                   :department_code="formData.department_code"
                                />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="start_date"
                                            rules=""
                                            v-slot="{ valid, errors }">
                            <b-form-group :label="$t('start_date')">
                                <select-date v-model="formData.start_date"
                                             :validation-error="errors[0]"
                                />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="end_date"
                                            rules=""
                                            v-slot="{ valid, errors }">
                            <b-form-group :label="$t('end_date')">
                                <select-date v-model="formData.end_date"
                                             :validation-error="errors[0]"
                                />
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="d-flex justify-content-end mt-2 mb-2">
                        <b-button variant="primary"
                                  @click="createForm"
                                  :disabled="formLoading"
                                  block
                        >
                            {{ $t('add') }}
                        </b-button>
                    </div>
                </ValidationObserver>
            </b-col>
            <b-col cols="12" md="9">
                <p class="font-weight-medium  mb-3">{{ $t('authorizations').toUpper() }}</p>
                <div v-if="data.length>0">
                    <table class="table table-sm table-bordered">
                        <tr>
                            <th class="text-center align-bottom">{{ toUpperCase('role') }}</th>
                            <th class="align-bottom">
                                {{ toUpperCase('unit') }}
                            </th>
                            <th class="text-center align-bottom" v-html="toUpperCase('start_date').replace(' ','<br>')"></th>
                            <th class="text-center align-bottom" v-html="toUpperCase('end_date').replace(' ','<br>')"></th>
                            <th></th>
                        </tr>
                        <tr v-for="(item, key) in data" v-bind:key="key">
                            <td class="align-middle text-center">{{ item.name }}</td>
                            <td class="align-middle">
                                <span>{{ item.faculty_code }} - {{ item.faculty_name }}</span>
                                <span v-if="item.department_name"><br>{{ item.department_code }} - {{ item.department_name }}</span>
                                <span v-if="item.program_name"><br>{{ item.program_code }} - {{ item.program_name }}</span>
                            </td>
                            <td class="align-middle text-center">{{ toLocaleDate(item.start_date) }}</td>
                            <td class="align-middle text-center">{{ toLocaleDate(item.end_date) }}</td>
                            <td width="50">
                                <b-button variant="danger"
                                          @click="deleteForm(item)">
                                    {{ $t('delete') | toUpperCase }}
                                </b-button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div v-else>
                    <hr>
                    <p class="text-center mt-5">{{ $t('there_is_no_authorization') }}</p>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    // Components
    import AcademicYearsSelectbox from '@/components/interactive-fields/AcademicYearsSelectbox';
    import DepartmentSelectbox from '@/components/interactive-fields/DepartmentSelectbox';
    import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox';
    import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox';
    import RoleSelectbox from '@/components/interactive-fields/RoleSelectbox';
    import MultiSelectbox from '../../../components/interactive-fields/MultiSelectbox'
    import SelectDate from '@/components/interactive-fields/SelectDate';

    // Services
    import AssignRoleService from '@/services/AssignRoleService';
    import RoleService from '@/services/RoleService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate';


    export default {
        components: {
            AcademicYearsSelectbox,
            DepartmentSelectbox,
            FacultySelectbox,
            ProgramSelectbox,
            RoleSelectbox,
            SelectDate,
            MultiSelectbox,
            ValidationProvider,
            ValidationObserver
        },
        props: {
            userId: {
                type: Number
            }
        },
        data() {
            return {
                tabIndex: 0,
                formData: {},
                formLoading: false,
                data: [],
                giveableRoleOptions: []
            }
        },
        created() {
            this.getRoles()
            this.getGiveableRoles()
        },
        methods: {
            clearForm() {
                this.formData = {
                    role_id: null,
                    faculty_code: null,
                    department_code: null,
                    program_code: null,
                    start_date: null,
                    end_date: null
                }
            },
            getRoles() {
                this.data = [];
                AssignRoleService.get(this.userId)
                                 .then((responseAssignRole) => {
                                     let response = responseAssignRole.data.data;
                                     if (response && response.length) {
                                         this.data = response
                                         //Sadece Admin role'ü görüdüğü için yorumda.
                                         // this.data = response.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                                     }
                                 })
                                 .catch((e) => {
                                     this.showErrors(e);
                                 })
            },
            getGiveableRoles() {
                RoleService.getGiveableRoles()
                    .then((response) => {
					    response?.data?.data?.map(item => {
                            const option = {}
						    option.value = item['id']
                            option.text = this.getLocaleText(item, 'explanation')
						        this.giveableRoleOptions.push(option)
                            })
                    })
                    .catch((error) => {
                        this.showErrors(error)
                    })
            },
            async createForm() {
                if (this.checkPermission('assignrole_store')) {
                    const isValid = await this.$refs.formModalValidate.validate();
                    if (isValid) {
                        this.formLoading = true;

                        let formData = {
                            model_id: this.userId,
                            ...this.formData
                        }

                        AssignRoleService.store(formData)
                                         .then((response) => {
                                             this.$toast.success(this.$t('api.' + response.data.message));
                                             this.$refs.formModalValidate.reset()
                                             this.clearForm()
                                             this.getRoles()
                                         })
                                         .catch((error) => {
                                             this.showErrors(error, this.$refs.formModalValidate)
                                         })
                                         .finally(() => {
                                             this.formLoading = false
                                         })
                    }
                }
                else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            deleteForm(form) {
                if (this.checkPermission('assignrole_delete')) {
                    this.$swal
                        .fire({
                            text: this.$t('are_you_sure_to_delete'),
                            showCancelButton: true,
                            confirmButtonText: this.$t('yes'),
                            cancelButtonText: this.$t('no')
                        })
                        .then((result) => {
                            if (result.isConfirmed) {
                                let formData = {
                                    model_id: this.userId,
                                    role_id: form.id,
                                    faculty_code: form.faculty_code,
                                    department_code: form.department_code,
                                    program_code: form.program_code
                                }
                                AssignRoleService.del(formData)
                                                 .then(response => {
                                                     this.clearForm()
                                                     this.getRoles()
                                                     this.$toast.success(this.$t('api.' + response.data.message));
                                                 })
                                                 .catch(e => {
                                                     this.showErrors(e)
                                                 })
                            }
                        })
                }
                else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            }
        }
    }
</script>

